import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { NotFoundContainer } from './containers/NotFoundContainer/NotFoundContainer';
import { PrivacyPolicyContainer } from './containers/PrivacyPolicyContainer/PrivacyPolicyContainer';
import { RootContainer } from './containers/RootContainer/RootContainer';
import { MainLayout } from './layouts/MainLayout/MainLayout';
import './styles/global.scss';
import { initGA } from './utils/initGA';

function App() {

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, [])
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }
      >
          <Route path="*" element={<NotFoundContainer />} />
          <Route path='/' element={<RootContainer />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyContainer />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
