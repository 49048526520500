import { Heading } from "../../components/HomePage/Heading/Heading"
import { TeamContainer } from "../../components/HomePage/TeamContainer/TeamContainer"
import { Community } from "../../components/HomePage/Community/Community"
import { SlidersContainer } from "../../components/HomePage/SlidersContainer/SlidersContainer"
import { useEffect } from "react"
import { Card } from "../../components/HomePage/Card/Card"
import { Profit } from "../../components/HomePage/Profit/Profit"
import { Features } from "../../components/HomePage/Features/Features"
import { Financing } from "../../components/HomePage/Financing/Financing"
import { Section } from "../../components/Section/Section"

export const RootContainer = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <div id="yoosr">
        <Section>
          <Heading />
        </Section>
      </div>
      <div id="riba-free">
        <Section>
          <Card />
        </Section>
      </div>
      <div id="profit">
        <Section>
          <Profit />
        </Section>
      </div>
      <Section>
        <Features />
      </Section>
      <div id="charity">
        <Section small>
          <Financing />
        </Section>
      </div>
      <div id="community">
        <Section>
          <Community />
        </Section>
      </div>
      <div id="team">
        <Section>
          <TeamContainer />
        </Section>
      </div>
      <div id="faq">
        <Section>
          <SlidersContainer />
        </Section>
      </div>
    </>
  )
}