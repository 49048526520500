import { FC, ReactNode } from 'react';
import styles from './Section.module.scss';
import classNames from 'classnames';

interface IProps {
  children: ReactNode;
  small?: boolean;
}

export const Section: FC<IProps> = ({ children, small }) => {
  return (
    <div className={classNames(styles.root, {
      [styles.root__small]: small,
    })}>{children}</div>
  )
}