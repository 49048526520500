import { FC, ReactNode, useState } from 'react';
import { Typography } from '../../Typography/Typography';
import styles from './SlidersContainer.module.scss';
import { ReactComponent as Plus } from '../../../images/Plus.svg';
import { ReactComponent as Minus } from '../../../images/Minus.svg';

interface SliderProps {
  title: string;
  content: ReactNode;
  opened: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const Slider: FC<SliderProps> = ({ title, content, onOpen, onClose, opened }) => {
  const handleClick = () => {
    if (opened) {
      onClose();
    } else {
      onOpen();
    }
  }

  return (
    <div className={styles.slider} onClick={handleClick}>
      <div className={styles.top}>
        <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>{title}</Typography>
        {opened ? <Minus /> : <Plus />}
      </div>
      {opened && (
        <div className={styles.content}>
          {content}
        </div>
      )}
    </div>
  )
}

export const SlidersContainer = () => {
  const [opened, setOpened] = useState<number>(0);
  return (
    <div className={styles.root}>
      <Slider
        title="Why everyone should bank the Islamic way?"
        opened={opened === 1}
        onOpen={() => setOpened(1)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            The traditional banking preconceives that money can grow independently of any real assets and resources backing its growth; it can grow based on the "promise of permanent success in the future".
            <br /><br />
            The assumption is basically that "if the Earth is done and finished, there will be Mars to venture on and to continue backing interest payments".
            <br /><br />
            The global debt has risen from $226 trillion in 2020 to $303 trillion in 2021. Mars might not be enough soon, and until it is also finished, the interest payments will obviously amount to much more.
            <br /><br />
            Another problem is that this venture, the risk of it all, is almost entirely transferred by the financial institutions to those who are doing the extraction and processing of the resources, i.e. the real work.
            <br /><br />
            The banks say: "We'll give you the money that does not exist; we will do nothing; you venture, but if you fail we'll take everything from you, plus more than you have. All risks are yours; if we inflate it too much, your taxes will cover it".
            <br /><br />
            In a nutshell, Islamic banking defies both these assumptions: resources are limited and should be treated carefully, money can't be created out of bubble promises, and the risk is always shared by the parties involved.
          </Typography>
        }
      />
      <Slider
        title="When is Yoosr launching?"
        opened={opened === 2}
        onOpen={() => setOpened(2)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            In 2024. Support us on Social Media, join our community and share your thoughts. It will help us to launch faster. And we hope, in sha' Llah, promoting fairness with money will become a continuous sadaka for all of us.
          </Typography>
        }
      />
      <Slider
        title="Where will Yoosr be launching?"
        opened={opened === 3}
        onOpen={() => setOpened(3)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            We are launcing in the UK first, then expanding our service to the continental Europe, then South East Asia, then other Muslim and high Muslim presence countries. In sha' Llah
          </Typography>
        }
      />
      <Slider
        title="How is Yoosr gonna make money?"
        opened={opened === 4}
        onOpen={() => setOpened(4)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            Again, your support is crucial. In addition to a Free Plan, Subscriptions will be our main revenue stream.
            <br />
            The other revenue stream comes from partnering and sharing revenue with other Halal Businesses and projects.
          </Typography>
        }
      />
      <Slider
        title="How is Yoosr going to fulfil the promise of quality?"
        opened={opened === 5}
        onOpen={() => setOpened(5)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            Part of our team are former developers of Fintech giants, such as Revolut, Raiffeisen and other.
          </Typography>
        }
      />
      <Slider
        title="Does Yoosr avoid working with interest-based banks?"
        opened={opened === 6}
        onOpen={() => setOpened(6)}
        onClose={() => setOpened(0)}
        content={
          <>
            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              The closest we are to the banks is the following:
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              a) Working with so called BaaS (Banking as a Service) providers. However, they don’t earn from credit, their revenue is based on fees for providing legal and technological infrastructure. It’s like renting an office in a building where a bank also rents it. Not forbidden per se, but could be avoided.<br />
              b) Allowing Bank transfers from and to traditional banks.<br />
              c) Using fiat money which is, in the modern world, literally debt obligations. The most difficult one.
            </Typography>

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              BUT we are tackling all three issues:
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              a) We are going to apply for fintech licenses in the countries we operate in, which will allow us to built our own infrastructure. We’ll built first Muslim BaaS, which will be used for launching other credit-free products, in sha' Llah.<br />
              b) The more support we get from you, the more Muslims use our product, the less interabank transfers will be required. So it’s our collective responsibility to make transfers to credit-based banks less required. Again, show your support for the sake of Allah.<br />
              c) We’ll start working on developing a system based on converting fiat money into precious-metals backed stable digital currency. Long-shot, but Allah makes easy halal once we persevere through difficulties for his sake.
            </Typography>
          </>
        }
      />
      <Slider
        title="The doubtful practices of the Islamic finance and home financing"
        opened={opened === 7}
        onOpen={() => setOpened(7)}
        onClose={() => setOpened(0)}
        content={
          <>
            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              Sometimes there are no problems in fundamental principles of a particular Islamic financial instrument (like in the case of murabaha). In practice, however, these instruments are most often used with some tricks applied, becoming a disguised form of usury. At the same time, some of the so-called “Islamic” practices are simply forbidden (like al-‘ina, for example).
            </Typography>

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Islamic products of non-Islamic banks.
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              The capital is mixed with riba.
            </Typography>
            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              “And do not assist each other in acts of sinfulness and transgression..” (Quran 5:2)
            </Typography>

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Murabaha. Purchase financing, sale on credit.
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              In the case of murabaha, usually, customer says to a bank “I want to buy this thing”. The bank buys “this thing“ at its market price and sells it right back to the customer at a higher price in instalments (on deferred payment basis).
              <br /><br />
              The problem is that the bank didn't think or plan on buying those goods before customer asked for it. Banks can also use a binding promise on the customer to purchase “the thing” before buying it, or can reach an agreement with sellers about returning the goods if the customer eventually refuses to buy them.
              Basically, banks sell what they don’t own, hide a usual interest-based loan, and avoid risks.
              <br /><br />
              <u>Hadith</u><br />
              “Do not sell what you do not possess.” (Abu Dawud 3503)
              <br /><br />
              <u>Commentary</u><br />
              Sheikh Ash-Shinqiti says about murabaha (commentary to Umdat al-Ahkam, lecture format, translation of parts of the Sheikh's answer):<br />
              “...The least what can be said about this deal is doubtful... So, such contract is invalid. If bank buys some goods and is then selling it in instalments, no problem. But when you come and show what you want to buy, there is no any doubt that it is riba...”
            </Typography>

            <hr className={styles.line} />

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Al-‘ina. Loans and refinancing.
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              Customer says “I need cash”. The bank buys from the customer some asset the customer possesses, at its market price, for cash. Then the bank sells this asset right back to the customer, for a higher price, on deferred payment basis (in instalments). Such contract is not only a trick, but also mentioned specifically in a dedicated hadith.
              <br /><br />
              <u>Hadith</u><br />
              "When you enter into ‘ina transaction… Allah will make disgrace prevail over you..." (Abu Dawud 3503)
            </Typography>

            <hr className={styles.line} />

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Tawarruq. Reverse murabaha. Loans.
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              Customer says “I want to borrow cash”. Bank says “Buy from us this asset, which we fully possess (like gold, for example), in instalments (deferred payment) for a higher-than-market price. Bank also says “Then, you sell this asset for cash, at its market value, to a third party buyer. By the way, we know a buyer willing to buy this asset right now. And even better, we will do a “serviced” sale on your behalf”.
              <br /><br />
              That way bank tries to shift the issue of “selling what they don’t own” by involving a multiple-party scheme, hoping to hide a plain cash loan.
            </Typography>

            <hr className={styles.line} />

            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Home financing
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              Islamic mortgage is typically based on murabaha or musharaka.<br />
              In case of murabaha, as we described above, financing providers normally buy the house you told them to buy, avoiding many risks.
              <br /><br />
              <i>Musharaka</i> is a term used when partners invest in enterprises and businesses together, and the risks are split between them. When applied to Islamic mortgage, musharaka means that the housing unit is purchased and owned by the provider and the customer in shares, and the ownership transfers gradually to the customer, following deferred instalment payments. However, musharaka also has two issues.
              <ol>
                <li>Customers show the exact property they want to buy. Financing providers try to avoid risks by making sure the transaction can be reverted with no financial burden in case something goes sideways.
                </li>
                <br />
                <li>
                  Instalment payment amount can change due to market fluctuations, which is an element of uncertainty (al-gharar).
                </li>
              </ol>
            </Typography>

            <hr className={styles.line} />
            <Typography variant='pMediumSemibold' font='Brahma' className={styles.title}>
              Islamic saving accounts. Sukuk.
            </Typography>

            <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
              Islamic alternative for bonds, called Sukuk, often serve as an underlying instrument of “Islamic” saving accounts.
              <br /><br />
              In 2007 the regulating authority for Islamic Finance, called the Accounting and Auditing Organisation for Islamic Financial Institutions (AAOIFI), stated that 85% of Sukuk are not halal. One of the arguments was that typical Sukuk had a guarantee of the repayment of the face value. Since then the mentioned authority has not made similar claims. However, there is no evidence to suggest that now Sukuk have become mostly halal.
              <br /><br />
              Even if 100% of Sukuk have become non-guaranteed, they are still underlined by the banking instruments based on or similar to murabaha or “Islamic mortgages”. In 2021, for example, one of the largest Sukuk funds had at least 28% of its Sukuk based on doubtful instruments.
            </Typography>

          </>
        }
      />
      <Slider
        title="How is Yoosr avoiding the doubtful?"
        opened={opened === 8}
        onOpen={() => setOpened(8)}
        onClose={() => setOpened(0)}
        content={
          <Typography variant='pSmallRegular' className={styles.text} font='Brahma' color='var(--text2)'>
            We are covering each issue and the solution on our social media, in our series of articles on "Islamic finance challenges". Follow us to learn how we can tackle each challenge!
          </Typography>
        }
      />
    </div>
  )
}
