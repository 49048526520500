import { Typography } from '../../Typography/Typography';
import styles from './Features.module.scss';
import { ReactComponent as Icon1 } from '../../../images/Icon1.svg';
import { ReactComponent as Icon2 } from '../../../images/Icon2.svg';
import { ReactComponent as Icon3 } from '../../../images/Icon3.svg';
import { ReactComponent as Icon4 } from '../../../images/Icon4.svg';
import { ReactComponent as Icon5 } from '../../../images/Icon5.svg';
import { ReactComponent as Icon6 } from '../../../images/Icon6.svg';
import { ReactComponent as Icon7 } from '../../../images/Icon7.svg';
import { ReactComponent as Icon8 } from '../../../images/Icon8.svg';

export const Features = () => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <Icon1 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          When shopping or doing groceries, Yoosr reminds you of du‘a, right before or during payment
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon2 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          All things zakat: nisab calendar, calculator, zakat distributors and zakat recipients
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon3 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Network of partners selling goods in instalments, without any involvement of interest-based banks
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon4 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Supporting community initiatives. Helping students or newly-wed, doing adha or zakat, and by doing much more
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon5 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Physical and virtual cards. Limited-usage virtual cards for relatives living abroad
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon6 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Join and help create a muslim business network; invest in and vouch for new businesses and their trustworthiness
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon7 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Goals for life events, hajj for parents or present for a spouse
        </Typography>
      </div>
      <div className={styles.card}>
        <Icon8 />
        <Typography variant='pMediumSemibold' className={styles.text} font='Brahma'>
          Convenience, transparency and top-notch, niche service
        </Typography>
      </div>
    </div>
  )
}