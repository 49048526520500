import { FC, ReactNode } from 'react';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import styles from './MainLayout.module.scss';

interface IProps {
  children: ReactNode;
}

export const MainLayout: FC<IProps> = ({ children }) => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.children}>
        {children}
      </div>
      <Footer />
    </div>
  )
};
