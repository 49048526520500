import { Typography } from '../../Typography/Typography';
import styles from './TeamContainer.module.scss';
// import Nick from '../../../images/Nick.png';
import Aldiyar from '../../../images/Aldiyar.png';
import Team from '../../../images/Icon13.svg';
import Arslan from '../../../images/Arslan.png';
import Nick from '../../../images/Nick.png';

export const TeamContainer = () => {
  return (
    <>
      <div className={styles.cards}>
        <div className={styles.card}>
          <img src={Arslan} alt="Arslan" />
          <div className={styles.textBlock}>
            <Typography variant='h3' font='Brahma' className={styles.title} align='left'>Arslan Ibrahim</Typography>
            <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.subtitle} align='left'>Co-Founder, ex-Deloitte, ex-Smartcat, c. Brighty app</Typography>
            <a href="https://www.linkedin.com/in/arslani/" target="_blank" rel="noreferrer">
              <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.link}>Linkedin</Typography>
            </a>
          </div>
        </div>
        <div className={styles.card}>
          <img src={Nick} alt="Nick" />
          <div className={styles.textBlock}>
            <Typography variant='h3' font='Brahma' className={styles.title} align='left'>Nik Denisenko</Typography>
            <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.subtitle} align='left'>Co-founder, CTO, co-founder at Brighty app, ex-Lead backend engineer at Revolut </Typography>
            <a href="https://www.linkedin.com/in/be1ive/" target="_blank" rel="noreferrer">
              <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.link}>Linkedin</Typography>
            </a>
          </div>
        </div>
        <div className={styles.card}>
          <img src={Aldiyar} alt="Aldiyar" />
          <div className={styles.textBlock}>
            <Typography variant='h3' font='Brahma' className={styles.title} align='left'>Aldiyar Aidash</Typography>
            <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.subtitle} align='left'>Head of Design, ex BTSDigital</Typography>
            <a href="https://www.linkedin.com/in/aldiyar-aidash-968270130/" target="_blank" rel="noreferrer">
              <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.link}>Linkedin</Typography>
            </a>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.imageWrapper}>
            <img src={Team} alt="Team" />
          </div>
          <div className={styles.textBlock}>
            <Typography variant='h3' font='Brahma' className={styles.title} align='left'>ex-Revolut</Typography>
            <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' className={styles.subtitle} align='left'>Dev team</Typography>
          </div>
        </div>
      </div>
    </>
  )
}