import styles from './Footer.module.scss';
import { ReactComponent as LogoIcon } from '../../images/LogoIcon.svg';
import { Typography } from '../Typography/Typography';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
        <LogoIcon />
        <div className={styles.column}>
        <Typography variant='caption' font='Brahma' className={styles.text}>©2023 Yoosr Ltd</Typography>
        <Typography variant='caption' font='Brahma' className={styles.text}>All rights reserved.</Typography>
        <a href="/privacy-policy" target='_blank'>
          <Typography variant='caption' font='Brahma' className={styles.link} color='var(--text2)'>Privacy Notice</Typography>
        </a>
        </div>
    </footer>
  )
}