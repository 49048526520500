export const countryCodes: { [code: string]: { name: string } } = {
  "AE": {
    "name": "United Arab Emirates"
  },
  "AL": {
    "name": "Albania"
  },
  "DZ": {
    "name": "Algeria"
  },
  "AD": {
    "name": "Andorra"
  },
  "AO": {
    "name": "Angola"
  },
  "AG": {
    "name": "Antigua and Barbuda"
  },
  "AR": {
    "name": "Argentina"
  },
  "AM": {
    "name": "Armenia"
  },
  "AU": {
    "name": "Australia"
  },
  "AT": {
    "name": "Austria"
  },
  "AZ": {
    "name": "Azerbaijan"
  },
  "BH": {
    "name": "Bahrain"
  },
  "BD": {
    "name": "Bangladesh"
  },
  "BB": {
    "name": "Barbados"
  },
  "BY": {
    "name": "Belarus"
  },
  "BE": {
    "name": "Belgium"
  },
  "BZ": {
    "name": "Belize"
  },
  "BJ": {
    "name": "Benin"
  },
  "BT": {
    "name": "Bhutan"
  },
  "BA": {
    "name": "Bosnia and Herzegovina"
  },
  "BW": {
    "name": "Botswana"
  },
  "BR": {
    "name": "Brazil"
  },
  "BN": {
    "name": "Brunei"
  },
  "BG": {
    "name": "Bulgaria"
  },
  "BF": {
    "name": "Burkina Faso"
  },
  "BI": {
    "name": "Burundi"
  },
  "CV": {
    "name": "Cabo Verde"
  },
  "KH": {
    "name": "Cambodia"
  },
  "CM": {
    "name": "Cameroon"
  },
  "CA": {
    "name": "Canada"
  },
  "CF": {
    "name": "Central African Republic"
  },
  "TD": {
    "name": "Chad"
  },
  "CL": {
    "name": "Chile"
  },
  "CO": {
    "name": "Colombia"
  },
  "KM": {
    "name": "Comoros"
  },
  "CG": {
    "name": "Congo"
  },
  "CR": {
    "name": "Costa Rica"
  },
  "HR": {
    "name": "Croatia"
  },
  "CU": {
    "name": "Cuba"
  },
  "CY": {
    "name": "Cyprus"
  },
  "CZ": {
    "name": "Czech Republic"
  },
  "DK": {
    "name": "Denmark"
  },
  "DJ": {
    "name": "Djibouti"
  },
  "DM": {
    "name": "Dominica"
  },
  "DO": {
    "name": "Dominican Republic"
  },
  "TL": {
    "name": "East Timor"
  },
  "EC": {
    "name": "Ecuador"
  },
  "EG": {
    "name": "Egypt"
  },
  "SV": {
    "name": "El Salvador"
  },
  "GQ": {
    "name": "Equatorial Guinea"
  },
  "ER": {
    "name": "Eritrea"
  },
  "EE": {
    "name": "Estonia"
  },
  "ET": {
    "name": "Ethiopia"
  },
  "FJ": {
    "name": "Fiji"
  },
  "FI": {
    "name": "Finland"
  },
  "FR": {
    "name": "France"
  },
  "GA": {
    "name": "Gabon"
  },
  "GM": {
    "name": "Gambia"
  },
  "GE": {
    "name": "Georgia"
  },
  "DE": {
    "name": "Germany"
  },
  "GH": {
    "name": "Ghana"
  },
  "GR": {
    "name": "Greece"
  },
  "GD": {
    "name": "Grenada"
  },
  "GT": {
    "name": "Guatemala"
  },
  "GN": {
    "name": "Guinea"
  },
  "GW": {
    "name": "Guinea-Bissau"
  },
  "GY": {
    "name": "Guyana"
  },
  "HT": {
    "name": "Haiti"
  },
  "HN": {
    "name": "Honduras"
  },
  "HU": {
    "name": "Hungary"
  },
  "IS": {
    "name": "Iceland"
  },
  "IN": {
    "name": "India"
  },
  "ID": {
    "name": "Indonesia"
  },
  "IQ": {
    "name": "Iraq"
  },
  "IE": {
    "name": "Ireland"
  },
  "IL": {
    "name": "Israel"
  },
  "IT": {
    "name": "Italy"
  },
  "JM": {
    "name": "Jamaica"
  },
  "JP": {
    "name": "Japan"
  },
  "JO": {
    "name": "Jordan"
  },
  "KZ": {
    "name": "Kazakhstan"
  },
  "KE": {
    "name": "Kenya"
  },
  "KI": {
    "name": "Kiribati"
  },
  "KW": {
    "name": "Kuwait"
  },
  "KG": {
    "name": "Kyrgyzstan"
  },
  "LA": {
    "name": "Laos"
  },
  "LV": {
    "name": "Latvia"
  },
  "LB": {
    "name": "Lebanon"
  },
  "LS": {
    "name": "Lesotho"
  },
  "LR": {
    "name": "Liberia"
  },
  "LY": {
    "name": "Libya"
  },
  "LI": {
    "name": "Liechtenstein"
  },
  "LT": {
    "name": "Lithuania"
  },
  "LU": {
    "name": "Luxembourg"
  },
  "MG": {
    "name": "Madagascar"
  },
  "MW": {
    "name": "Malawi"
  },
  "MY": {
    "name": "Malaysia"
  },
  "MV": {
    "name": "Maldives"
  },
  "ML": {
    "name": "Mali"
  },
  "MT": {
    "name": "Malta"
  },
  "MH": {
    "name": "Marshall Islands"
  },
  "MR": {
    "name": "Mauritania"
  },
  "MU": {
    "name": "Mauritius"
  },
  "MX": {
    "name": "Mexico"
  },
  "MD": {
    "name": "Moldova"
  },
  "MC": {
    "name": "Monaco"
  },
  "MN": {
    "name": "Mongolia"
  },
  "ME": {
    "name": "Montenegro"
  },
  "MA": {
    "name": "Morocco"
  },
  "MZ": {
    "name": "Mozambique"
  },
  "MM": {
    "name": "Myanmar"
  },
  "NA": {
    "name": "Namibia"
  },
  "NR": {
    "name": "Nauru"
  },
  "NP": {
    "name": "Nepal"
  },
  "NL": {
    "name": "Netherlands"
  },
  "NZ": {
    "name": "New Zealand"
  },
  "NI": {
    "name": "Nicaragua"
  },
  "NE": {
    "name": "Niger"
  },
  "NG": {
    "name": "Nigeria"
  },
  "NO": {
    "name": "Norway"
  },
  "OM": {
    "name": "Oman"
  },
  "PK": {
    "name": "Pakistan"
  },
  "PW": {
    "name": "Palau"
  },
  "PA": {
    "name": "Panama"
  },
  "PG": {
    "name": "Papua New Guinea"
  },
  "PY": {
    "name": "Paraguay"
  },
  "PE": {
    "name": "Peru"
  },
  "PH": {
    "name": "Philippines"
  },
  "PL": {
    "name": "Poland"
  },
  "PT": {
    "name": "Portugal"
  },
  "QA": {
    "name": "Qatar"
  },
  "RO": {
    "name": "Romania"
  },
  "RW": {
    "name": "Rwanda"
  },
  "KN": {
    "name": "Saint Kitts and Nevis"
  },
  "LC": {
    "name": "Saint Lucia"
  },
  "VC": {
    "name": "Saint Vincent and the Grenadines"
  },
  "WS": {
    "name": "Samoa"
  },
  "SM": {
    "name": "San Marino"
  },
  "ST": {
    "name": "Sao Tome and Principe"
  },
  "SA": {
    "name": "Saudi Arabia"
  },
  "SN": {
    "name": "Senegal"
  },
  "RS": {
    "name": "Serbia"
  },
  "SC": {
    "name": "Seychelles"
  },
  "SL": {
    "name": "Sierra Leone"
  },
  "SG": {
    "name": "Singapore"
  },
  "SK": {
    "name": "Slovakia"
  },
  "SI": {
    "name": "Slovenia"
  },
  "SB": {
    "name": "Solomon Islands"
  },
  "ZA": {
    "name": "South Africa"
  },
  "KR": {
    "name": "South Korea"
  },
  "SS": {
    "name": "South Sudan"
  },
  "ES": {
    "name": "Spain"
  },
  "LK": {
    "name": "Sri Lanka"
  },
  "SD": {
    "name": "Sudan"
  },
  "SR": {
    "name": "Suriname"
  },
  "SZ": {
    "name": "Eswatini"
  },
  "SE": {
    "name": "Sweden"
  },
  "CH": {
    "name": "Switzerland"
  },
  "TW": {
    "name": "Taiwan"
  },
  "TJ": {
    "name": "Tajikistan"
  },
  "TH": {
    "name": "Thailand"
  },
  "TG": {
    "name": "Togo"
  },
  "TO": {
    "name": "Tonga"
  },
  "TT": {
    "name": "Trinidad and Tobago"
  },
  "TN": {
    "name": "Tunisia"
  },
  "TR": {
    "name": "Turkey"
  },
  "TM": {
    "name": "Turkmenistan"
  },
  "TV": {
    "name": "Tuvalu"
  },
  "UG": {
    "name": "Uganda"
  },
  "UA": {
    "name": "Ukraine"
  },
  "GB": {
    "name": "United Kingdom"
  },
  "US": {
    "name": "United States"
  },
  "UY": {
    "name": "Uruguay"
  },
  "UZ": {
    "name": "Uzbekistan"
  },
  "VU": {
    "name": "Vanuatu"
  },
  "VE": {
    "name": "Venezuela"
  },
  "VN": {
    "name": "Vietnam"
  },
  "YE": {
    "name": "Yemen"
  },
  "ZM": {
    "name": "Zambia"
  },
  "ZW": {
    "name": "Zimbabwe"
  }
};
