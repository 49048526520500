/* eslint-disable react/jsx-no-bind */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import countryList from 'react-select-country-list';
import classNames from 'classnames';
import ArrowDownIcon from '../../images/arrow.svg';
import SearchIcon from '../../images/search-icon-gray.svg';
import { Typography } from '../Typography/Typography';
import styles from './CountrySelect.module.scss';
import { useBool } from '../../hooks/useBool';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { countryCodes } from '../../const/countryCodes';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface IProps {
  defaultValue?: { value: string; label: string };
  onChange: (value: { value: string; label: string }) => void;
  error?: boolean;
}

export const CountrySelect: FC<IProps> = ({ defaultValue, onChange, error }) => {
  const isOpen = useBool();
  const countryRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery('(max-width: 577px)');
  const [countryValue, setCountryValue] = useState<{ value: string; label: string } | undefined>(
    undefined,
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .filter((c: any) => countryCodes[c.value]),
    [],
  );
  useOutsideClick([countryRef], isOpen.onFalse);

  useEffect(() => {
    const country = countryOptions.find(
      (c: any) => c.value.toLowerCase() === defaultValue?.value.toLowerCase(),
    );
    if (country) {
      setCountryValue(country);
    }
    return () => {
      setCountryValue(undefined);
    };
  }, [defaultValue, countryOptions]);

  const toggleOpen = () => {
    isOpen.onToggle();
  };

  const foundCountries = useMemo(() => {
    if (!searchValue.trim()) {
      return countryOptions;
    } else {
      return countryOptions.filter((c: any) => c.label.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }, [searchValue, countryOptions]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSelect = (country: { value: string; label: string }) => {
    setCountryValue(country);
    onChange(country);
    isOpen.onFalse();
  };

  const handleChangeSelect = (e: any) => {
    const country = JSON.parse(e.target.value);
    setCountryValue(country);
    onChange(country);
    isOpen.onFalse();
  };

  const handleClickInputWrapper = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!isOpen.value) {
      setSearchValue('');
    }
  }, [isOpen.value])

  if (isMobile) {
    return (
      <div className={styles.selectWrapper}>
        <select className={classNames(styles.select)} onChange={handleChangeSelect}>
          <option value="" selected hidden>Country of your residence</option>
          {foundCountries.map((c: any) => (
            <option
              key={c.value}
              className={styles.searchListItem}
              value={JSON.stringify(c)}
            >
              <Typography variant="pMediumSemibold">{c.label.split(',')[0]}</Typography>
            </option>
          ))}
        </select>
      </div>
    )
  }

  return (
    <div
      ref={countryRef}
      className={classNames(styles.inputContainer, {
        [styles.inputContainer__error]: error,
      })}
      onClick={toggleOpen}
    >
      <Typography
        className={classNames(styles.placeholder, {
          [styles.placeholder__focused]: countryValue,
        })}
        variant="pSmallRegular"
      >
        Country of your residence
      </Typography>
      <Typography
        className={classNames(styles.value, {
          [styles.value__focused]: countryValue,
        })}
        variant={countryValue ? 'pSmallRegular' : 'pMediumSemibold'}
      >
        {countryValue ? countryValue.label : 'Country of your residence'}
      </Typography>
      <div
        className={classNames(styles.iconContainer, {
          [styles.iconContainer__rotated]: isOpen.value,
        })}
      >
        <img src={ArrowDownIcon} alt="" />
      </div>
      {isOpen.value && (
        <div
          className={styles.dropdown}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.searchInputContainer} onClick={handleClickInputWrapper}>
            <img src={SearchIcon} alt="" />
            <input
              ref={inputRef}
              className={styles.searchInput}
              placeholder="Search"
              onChange={handleChangeSearch}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className={styles.searchList}>
            {foundCountries.map((c: any) => (
              <div
                key={c.value}
                className={styles.searchListItem}
                onClick={handleSelect.bind(this, c)}
              >
                <Typography variant="pMediumSemibold">{c.label.split(',')[0]}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
