import { Typography } from '../../Typography/Typography';
import styles from './Card.module.scss';
import { ReactComponent as NoRiba } from '../../../images/NoRiba.svg';
import { ReactComponent as NoTricks } from '../../../images/NoTricks.svg';

export const Card = () => {
  return (
    <>
      <div className={styles.slogan}>
        <Typography variant='h0' align='center' className={styles.title}>
          A riba-free financial app, all the way
        </Typography>
        <Typography variant='pMediumSemibold' align='center' className={styles.subtitle} font='Brahma'>
          Muslim financial values aren’t just about Arabic words. It’s about the meaning of money, which is amana entrusted to us to be used fairly.
        </Typography>
      </div>
      <div className={styles.bottom}>
        <div className={styles.badges}>

          <NoRiba />
          <NoTricks />
        </div>

        <div className={styles.conclusion}>
          <Typography variant='h3'>Avoid the doubtful, feel secure in your belief</Typography>
          <Typography variant='pMediumRegular' className={styles.subtitle} color='var(--text2)'>Learn more about doubtful Islamic Finance practices in our FAQ section </Typography>
        </div>
      </div>
    </>
  )
}