import { useEffect } from 'react';
import { Spacer } from '../../components/Spacer/Spacer';
import { Typography } from '../../components/Typography/Typography';
import styles from './PrivacyPolicyContainer.module.scss';

export const PrivacyPolicyContainer = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Spacer h={72} />
        <Typography variant='h1'>Privacy Notice</Typography>
        <Spacer h={32} />
        <Typography variant='pLargeRegular'>Yoosr Ltd.
          <br />
          Last updated: 23 February 2022.
        </Typography>
        <Spacer h={144} hm={64} />
        <div className={styles.cards}>
          <div className={styles.card}>
            <Typography variant='pMediumRegular'>
              This Privacy Notice refers to the website www.yoosr.com (in the following the «Website») and describes the information that is collected via the website. Where technically possible and reasonable, we will, on the request of the user, ensure that use of the website is possible in an anonymised or pseudonymised form. Within the meaning of this privacy policy, personal data is any information relating to an identified or identifiable natural person.
              <br />Please note that data transmission via the Internet (e.g. communication by e-mail) may be subject to security vulnerabilities. It is not possible to protect the data completely against access by third parties.
              <br />This policy may be updated at any time. You are therefore advised to read the latest version of this page regularly. The first sentence in the policy states the date of the most recent update.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>Area of Application</Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              This Privacy Notice applies only to the use of the website www.yoosr.com and therefore, in particular, not to:
              <ul>
                <li>use of the Yoosr app.</li>
                <li>other websites to which you are referred by clicking on a link.</li>
              </ul>
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Categories of personal data
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              The following categories of personal data may be collected, processed and used
              by Yoosr in connection with a visit to the website:
              <ul>
                <li>
                  IP address, operating system, browser type, browser version, browser configuration, name of the Internet service provider and other types of information relating to the devices used to access our website, as well as the connection that is relevant to identify the type of device operated by the user, to connect with the website, to enable data exchange with the user and the device operated by the user and to guarantee convenient use of the website;
                </li>
                <li>
                  URL and IP address of the website from which the user accessed our website or that redirected the user to our website, including the date and time;
                </li>
                <li>
                  Email address, telephone number, country of residence entered for Yoosr’s personal use without transferring to third parties.
                </li>
              </ul>
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Contractual obligations
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              <ul>
                <li>
                  Yoosr primarily processes personal data as part of initiating or executing contracts, particularly to...to manage, operate, maintain and optimize the website and the services provided thereon;
                </li>
                <li>
                  to help us improve the website and our services;
                </li>
                <li>
                  to answer questions put to us by users and to attend to the concerns of users;
                </li>
                <li>
                  to be able to show information and advertising about our products to users; and
                </li>
                <li>
                  to prevent the illegal use of our website.
                </li>
              </ul>
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Legal obligations
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              Yoosr may be required by law or an official order to provide information, report your personal data or hand over your personal data.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Newsletter data
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              If you would like to order the newsletter offered on the website, we require your email address and information enabling us to verify that you own the email address given and have agreed to receive the newsletter. We store your IP address and the date of registration when you subscribe to our newsletter. This data is used solely for the purposes of verification in case a third party misuses the email address and registers to receive the newsletter without the knowledge of the person authorized to use the account.
              No other data will be collected. We use this data for the sole purpose of sending the requested information and shall not pass it on to third parties.
              You may withdraw your consent at any time for the storage of your data, your email address and the usage thereof to send the newsletter, for example by using the unsubscribe link in the newsletter.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Recipients of personal data
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              Third parties contracted by us that implement technical and organizational measures on our behalf, that act according to our instructions and that we require for the performance of the purposes set out in this Privacy Notice or for our other business activities (e.g. customer and technical support, etc. ) may have access to personal data belonging to users. Our service providers are required to process personal data exclusively on our behalf and according to our instructions. We also require our service providers to maintain technical and organisational measures for the protection of personal data.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Risk of data transfers
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              Data transmitted via public networks like the Internet or email services can be accessed by anyone. Information transferred via the Internet (such as online forms) and content received online may be transmitted via networks operated by third parties. Yoosr cannot guarantee the confidentiality of information or documents that are transmitted by means of these public networks or networks operated by third parties.
              If you disclose personal data via a public network or networks operated by third party providers, you should be aware of the fact that your data may be lost. Also third parties might gain access and hence collect and use the data without your consent. While many of the individual data packages are transmitted in an encrypted form, the names of the sender and the recipient are not. Third parties can therefore draw conclusions on accounts and business relationships that exist or are being established. Even if the sender and the recipient are residents of the same country, the transmission of data over these networks frequently – and without monitoring – takes place via third countries, so also via countries that do not offer the same degree of protection as the country in which you are domiciled. We do not accept responsibility for the security of your data during transfer via the Internet and reject all liability for direct and indirect damages. The use of other means of communication is permitted if you consider this to be necessary or reasonable for security reasons.
              Data that you enter in online forms may be passed on to and processed by third parties for the performance of the order. Your data will be transferred with advanced encryption for certain services.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Right of access and erasure, suppression and rectification of personal data
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              On your request, Yoosr will provide you with information on all of the personal data we have stored about you, its source, the recipients and categories of recipients to whom we have disclosed personal data about you and on the purpose of storage or data processing. You also have the right to demand the rectification, blocking or erasure of this data. Any consent given can be withdrawn at any time by notifying Yoosr.
            </Typography>
          </div>
          <div className={styles.card}>
            <Typography variant='pLargeSemibold'>
              Links
            </Typography>
            <Spacer h={16} />
            <Typography variant='pMediumRegular'>
              This website may include links to other websites that are not operated or monitored by Yoosr. These websites are not subject to the terms of this policy, and we are neither responsible for their contents nor for the principles by which they manage personal data. We recommend that you read the policies of the individual websites and that you review how they protect personal data, as well as their trustworthiness.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}