import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../images/Logo.svg';
import { Typography } from '../Typography/Typography';
import { useBool } from '../../hooks/useBool';
import { PreRegisterModal } from '../Modals/PreRegisterModal/PreRegisterModal';

export const Header = () => {
  const modalOpened = useBool();

  return (
    <header className={styles.root}>
        <a href='/#yoosr' className={styles.link}>
          <Logo />
        </a>
        <div className={styles.links}>
          <a href='#yoosr'>
            <Typography variant='pMediumSemibold' font='Brahma'>Yoosr</Typography>
          </a>
        <a href='#riba-free'>
          <Typography variant='pMediumSemibold' font='Brahma'>Riba-free</Typography>
        </a>
        <a href='#profit'>
          <Typography variant='pMediumSemibold' font='Brahma'>Profit</Typography>
        </a>
        <a href='#charity'>
          <Typography variant='pMediumSemibold' font='Brahma'>Home & Charity</Typography>
        </a>
        <a href='#community'>
          <Typography variant='pMediumSemibold' font='Brahma'>Community</Typography>
        </a>
        <a href='#team'>
          <Typography variant='pMediumSemibold' font='Brahma'>Team</Typography>
        </a>
        <a href='#faq'>
          <Typography variant='pMediumSemibold' font='Brahma'>FAQ</Typography>
        </a>
        </div>
          <button className={styles.basicButton} onClick={modalOpened.onTrue}>
            <Typography variant='pLargeSemibold' font='Brahma' color="var(--tertiary)" className={styles.basicButton__text}>Pre-register</Typography>
          </button>
        <PreRegisterModal open={modalOpened.value} onClose={modalOpened.onFalse} />
    </header>
  )
}