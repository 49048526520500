import styles from './Community.module.scss';
import { ReactComponent as Icon11 } from '../../../images/Icon11.svg';
import { ReactComponent as Icon12 } from '../../../images/Icon12.svg';
import { ReactComponent as Card } from '../../../images/card.svg';
import { ReactComponent as Friends } from '../../../images/Friends.svg';
import { ReactComponent as Zakat } from '../../../images/Zakat.svg';
import { useBool } from '../../../hooks/useBool';
import { Typography } from '../../Typography/Typography';
import classNames from 'classnames';
import { ReactComponent as Instargram } from '../../../images/instagram.svg';
import { ReactComponent as SocialX } from '../../../images/Social.X.svg';
import { ReactComponent as Linkedin } from '../../../images/linkedin.svg';
import { OutsideLinks } from '../../../const/OusideLinks';

export const Community = () => {
  const modalOpened = useBool();

  return (
    <>
      <div className={styles.cards}>
        <div className={styles.card}>
          <Icon11 />
          <Typography variant='h3' className={styles.title} align='left'>It’s for everyone who shares common values of interest-free lifestyle</Typography>
          <div className={styles.list}>
            <div className={styles.item}>
              <Card />
              <Typography variant='pSmallRegular' font='Brahma' className={styles.item__text}>Credit free lifestyle</Typography>
            </div>
            <div className={styles.item}>
              <Friends />
              <Typography variant='pSmallRegular' font='Brahma' className={styles.item__text}>Supporting and giving back to the community</Typography>
            </div>
            <div className={styles.item}>
              <Zakat />
              <Typography variant='pSmallRegular' font='Brahma' className={styles.item__text}>Love for charity</Typography>
            </div>
          </div>
        </div>
        <div className={classNames(styles.card, styles.card2)}>
          <Icon12 />
          <Typography variant='h3' color='var(--tertiary)' className={styles.title} align='left'>Building community</Typography>
          <Typography variant='pMediumSemibold' font='Brahma' className={styles.card2__text} color='var(--tertiary)' align='left'>We know you might not want to engage in haram but you feel bound to the ways of the world. Yoosr provides you with the alternative, halal options you have been waiting for. Remember that when you leave haram, and persevere through difficulties, Allah replaces it with what is much better!</Typography>
          <div className={styles.badges}>
            <div className={styles.badge}>
              <Typography variant='h3' color='var(--tertiary)'>
                Together
              </Typography>
            </div>
            <div className={styles.badge}>
              <Typography variant='h3' color='var(--tertiary)'>
                we can change
              </Typography>
            </div>
            <div className={styles.badge}>
              <Typography variant='h3' color='var(--tertiary)'>
                the face
              </Typography>
            </div>
            <div className={styles.badge}>
              <Typography variant='h3' color='var(--primary)'>
                of finance
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.buttons}>
          <button className={styles.basicButton} onClick={modalOpened.onTrue}>
            <Typography variant='h3' color="var(--tertiary)" className={styles.basicButton__text} font='Brahma'>Pre-register</Typography>
          </button>
          <a href={OutsideLinks.Twitter} target="_blank" rel="noreferrer">
            <button className={styles.socialButton}>
              <SocialX />
            </button>
          </a>
          <a href={OutsideLinks.Instagram} target="_blank" rel="noreferrer">
            <button className={styles.socialButton}>
              <Instargram />
            </button>
          </a>
          <a href={OutsideLinks.Linkedin} target="_blank" rel="noreferrer">
            <button className={styles.socialButton}>
              <Linkedin />
            </button>
          </a>
        </div>
        <Typography variant='pMediumRegular' font='Brahma' color='var(--text2)' align='center' mobileAlign='center'>Join our community.</Typography>
      </div>
    </>
  )
}