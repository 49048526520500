import { useNavigate } from "react-router-dom";
import { Spacer } from "../../components/Spacer/Spacer";
import { Typography } from "../../components/Typography/Typography"
import styles from './NotFoundContainer.module.scss';

export const NotFoundContainer = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <Typography variant="h1">Oops! Page not found</Typography>
      <Spacer h={32} />
      <Typography variant="h4">404 – You’ve found a page that doesn’t exist</Typography>
      <Spacer h={8} />
      <Typography variant='pSmallRegular'>Breathe in, and on the out breath, go back and try again.</Typography>
      <Spacer h={32} />
      <button onClick={() => navigate('/')}>
        Back to home
      </button>
    </div>
  )
}