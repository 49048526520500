import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useBool } from '../../../hooks/useBool';
import { CountrySelect } from '../../CountrySelect/CountrySelect';
import { Spacer } from '../../Spacer/Spacer';
import { Typography } from '../../Typography/Typography';
import styles from './PreRegisterModal.module.scss';
import CloseIcon from '../../../images/Close.svg';
import emailjs from '@emailjs/browser';
import Heart from '../../../images/heart.svg';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const PreRegisterModal: FC<IProps> = ({ open, onClose }) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [validateError, setValidateError] = useState(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [inviteeEmailValue, setInviteeEmailValue] = useState<string>('');
  const [countryValue, setCountryValue] = useState<{ value: string; label: string } | undefined>(
    undefined,
  );
  const success = useBool();
  const termsChecked = useBool();
  const loading = useBool();

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmailValid(e.target.value.includes('@'));
    setEmailValue(e.target.value);
  };

  const handleChangeInviteeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteeEmailValue(e.target.value);
  };

  const handleChangeCountry = (value: any) => {
    setCountryValue(value);
  };

  const handleClose = () => {
    setEmailValue('');
    setInviteeEmailValue('');
    setValidateError(false);
    termsChecked.onFalse();
    setIsEmailValid(false);
    loading.onFalse();
    success.onFalse();
    onClose();
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [open]);

  const closePopupOnOverlay = useCallback(
    (evt: any) => {
      if (evt.target === evt.currentTarget) {
        onClose();
      }
    },
    [onClose],
  );

  if (!open) {
    return null;
  }

  const sendEmail = async () => {
    if (!countryValue || !emailValue.trim() || !termsChecked.value) {
      setValidateError(true);
      return;
    }
    if (countryValue) {
      loading.onTrue()
      await emailjs.send(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, {
        email: emailValue,
        country: countryValue.label,
        inviteeEmail: inviteeEmailValue,
      }, process.env.REACT_APP_MAIL_PUBLIC_KEY).then(() => 
        success.onTrue()
      )
    }
  };

  if (success.value) {
    return (
      <div className={styles.wrapper} onClick={closePopupOnOverlay}>
      <div className={styles.modal}>
        <img src={Heart} className={styles.heart} alt="heart"/>
        <Spacer h={24} />
        <Typography variant='pLargeSemibold'>We received your pre-registration.</Typography>
        <Spacer h={8} />
        <Typography variant='pMediumRegular'>Jazak Allahu khairan for your support!</Typography>
        <Spacer h={24} />
        <button className={classNames(styles.sendButton, styles.sendButton__close)} onClick={handleClose}>
          <Typography variant='pLargeSemibold' align='center'>Close</Typography>
        </button>
        <button className={styles.closeButton} onClick={handleClose}>
          <img src={CloseIcon} alt=""/>
        </button>
      </div>
    </div>
    )
  }

  return (
    <div className={styles.wrapper} onClick={closePopupOnOverlay}>
      <div className={styles.modal}>
        <Spacer h={8} />
        <Typography variant='h4'><b>Pre-register</b></Typography>
        <Spacer h={24} />
        <Typography variant='pMediumRegular'>Leave your details to receive updates and show your support which is crucial for our team!</Typography>
        <Spacer h={24} />
        <CountrySelect onChange={handleChangeCountry} error={validateError && !countryValue} />
        <Spacer h={8} />
        <input
          id="orderEmailInput"
          className={classNames(styles.input, {
            [styles.input__error]: !isEmailValid && validateError,
          })}
          name={'email'}
          type="email"
          placeholder="Your email"
          value={emailValue}
          onChange={handleChangeEmail}
          autoComplete="off"
        />
        <Spacer h={8} />
        <input
          id="orderInviteeEmailInput"
          className={classNames(styles.input, {
            [styles.input__error]: !isEmailValid && validateError,
          })}
          name={'inviteeEmail'}
          type="email"
          placeholder="Email of whom invited you (optional)"
          value={inviteeEmailValue}
          onChange={handleChangeInviteeEmail}
          autoComplete="off"
        />
        <Spacer h={24} />
        <label className={styles.pseudoCheckboxLabel} htmlFor="termsCheckboxAgreement">
          <input
            id="termsCheckboxAgreement"
            className={styles.pseudoCheckboxInput}
            type="checkbox"
            name='pp'
            defaultChecked={false}
            onChange={termsChecked.onToggle}
            required
          />
          <span className={styles.pseudoCheckbox} />
          <Typography
            className={classNames(styles.pseudoCheckboxText, {
              [styles.pseudoCheckboxText__error]: validateError && !termsChecked.value,
            })}
            variant="pSmallRegular"
          >
            I accept&nbsp;
            <a target="_blank" href="/privacy-policy">
              Privacy Policy
            </a>
          </Typography>
        </label>
        <Spacer h={24} />
        <button className={styles.sendButton} onClick={sendEmail} disabled={loading.value}>
          <Typography variant='pLargeSemibold' align='center' color="var(--white)">Send</Typography>
        </button>
        <button className={styles.closeButton} onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
    </div>
  )
}
