import { Typography } from '../../Typography/Typography';
import styles from './Profit.module.scss';
import { ReactComponent as Bank } from '../../../images/Bank.svg';
import { ReactComponent as Symbol } from '../../../images/Symbol.svg';
import { ReactComponent as Notification } from '../../../images/Notification.svg';
import classNames from 'classnames';
import { ReactComponent as Check } from '../../../images/Check.Filled.svg';
import { ReactComponent as Icon } from '../../../images/Icon.svg';
import TypeWriterEffect from 'react-typewriter-effect';

const ListItem = ({ title }: { title: string }) => {
  return (
    <div className={styles.listItem}>
      <Check />
      <Typography variant='pSmallSemibold' font='Brahma'>{title}</Typography>
    </div>
  )
}

const list = ['Аccounts', 'Cards', 'Transfers', 'Payments', 'Different currencies', 'Instant exchange']

export const Profit = () => {
  return (
    <>
      <Typography variant='h0' className={styles.title} align='left'>Money turned into true profit</Typography>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <Bank />
          </div>
          <Typography variant='h3' align='left'>Core banking features</Typography>
          <div className={styles.list}>
            {list.map((l) => <ListItem title={l} />)}
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <Notification />
          </div>
          <Typography variant='h3' align='left'>Reminders about events and du‘a. Turning shopping into reward</Typography>
          <div className={styles.list2}>
            <div className={styles.listItem2}>
              <Icon />
              <Typography className={styles.listItem2__text} color='var(--tertiary)' font='Brahma' align='left'>
                At the store now? Remember to recite du‘a. Tap here to read it
              </Typography>
            </div>
            <div className={styles.listItem2}>
              <Icon />
              <Typography className={styles.listItem2__text} color='var(--tertiary)' font='Brahma'>
                Your nisab was reached in Shaʽban 1443. Sha’ban 1444 starts in 10 days. Count and pay your zakaat with Yoosr
              </Typography>
            </div>
          </div>
        </div>
        <div className={classNames(styles.card, styles.card__black)}>
          <div className={styles.iconWrapper}>
            <Symbol />
          </div>
          <Typography variant='h3' color="var(--text1)" align='left'>
            Community initiatives
          </Typography>
          <TypeWriterEffect
            startDelay={100}
            cursorColor="white"
            text="This is a single text"
            textStyle={{
              fontFamily: 'Brahma',
              color: 'var(--white)',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              textAlign: 'left'
            }}
            multiText={[
              'joint adha',
              'organizing aqiqa',
              'opening a school',
              'joint zakat al-fitr',
              'building a mosque',
              'collecting for medical aid',
              'investing in enteprise',
              'helping a student',
              'helping to buy a house',
              'renovating a mosque'
            ]}
            // multiTextDelay={1000}
            typeSpeed={100}
            multiTextLoop={true}
          />
        </div>
      </div>
    </>
  )
}