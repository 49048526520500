import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface IProps {
  className?: string;
  children: ReactNode;
  align?: 'center' | 'left' | 'right';
  mobileAlign?: 'center' | 'left' | 'right';
  color?: string;
  font?: 'Aeonik' | 'Brahma'
  variant?:
    | 'h0'
    | 'h1'
    | 'h2'
    | 'h2Regular'
    | 'h3'
    | 'h4'
    | 'pLargeSemibold'
    | 'pLargeRegular'
    | 'pMediumSemibold'
    | 'pMediumRegular'
    | 'pSmallSemibold'
    | 'pSmallRegular'
    | 'caption'
}

export const Typography: FC<IProps> = ({
  className,
  children,
  variant = 'pMediumSemibold',
  align = 'left',
  mobileAlign = 'left',
  color = 'var(--text0)',
  font = 'Aeonik',
}) => {
  const isMobile = useMediaQuery('(max-width: 577px)');

  const globalAlign = useMemo(() => {
    if (isMobile) {
      if (!mobileAlign) {
        return align;
      } else {
        return mobileAlign;
      }
    } else {
      return align;
    }
  }, [align, mobileAlign, isMobile])

  if (variant === 'h1' || variant === 'h0') {
    return (
      <h1
        className={classNames(styles.text, styles[variant], styles[`font_${font}`], className )}
        style={{ textAlign: globalAlign, color }}
      >
        {children}
      </h1>
    );
  }

  return (
    <p className={classNames(styles.text, styles[variant], styles[`font_${font}`], className)} style={{ textAlign: globalAlign, color }}>
      {children}
    </p>
  );
};
