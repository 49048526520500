import { Typography } from '../../Typography/Typography';
import styles from './Financing.module.scss';
import { ReactComponent as Icon9 } from '../../../images/Icon9.svg';
import { ReactComponent as Icon10 } from '../../../images/Icon10.svg';

export const Financing = () => {
  return (
    <>
      <div className={styles.top}>
        <div className={styles.badge}>
          <Typography variant='h3' color="var(--tertiary)">Home financing</Typography>
        </div>
        <Typography variant='h0' align='center' className={styles.title}>No financial tricks and loopholes.<br />Rely on the Islamic values of honesty, transparency and community</Typography>
      </div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <Icon9 />
          <div className={styles.textBlock}>
            <Typography variant='h3' align='left'>
              Home financing
            </Typography>
            <Typography variant='pSmallRegular' color='var(--text2)' className={styles.text} font='Brahma' align='left'>
              User-community sponsors housing, housing is pre-select, instalment payments depend on income, proceeds sponsor new homes
            </Typography>
          </div>
        </div>
        <div className={styles.card}>
          <Icon10 />
          <div className={styles.textBlock}>
            <Typography variant='h3' align='left'>
              Charity
            </Typography>
            <Typography variant='pSmallRegular' color='var(--text2)' className={styles.text} font='Brahma' align='left'>
              Select parnet charities, transparent financials, no hidden costs, no smart accounting, transparent reporting
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.quotes}>
        <div className={styles.quote}>
          <div className={styles.number}>
            16:80
          </div>
          <Typography variant='pSmallSemibold' font='Brahma' align='left'>Allah has made your houses a place of comfort for you</Typography>
        </div>
        <div className={styles.quote}>
          <div className={styles.number}>
            34:39
          </div>
          <Typography variant='pSmallSemibold' font='Brahma' align='left'>Whatever you spend, He will replace it. He is the Best of all Providers</Typography>
        </div>
      </div>
    </>
  )
}