import { Typography } from '../../Typography/Typography';
import styles from './Heading.module.scss';
import { OutsideLinks } from '../../../const/OusideLinks';
import { ReactComponent as Instargram } from '../../../images/instagram.svg';
import { ReactComponent as SocialX } from '../../../images/Social.X.svg';
import { ReactComponent as Linkedin } from '../../../images/linkedin.svg';
import TechTimes from '../../../images/TechTimes.svg';
import Nasdaq from '../../../images/Nasdaq.svg';
import ArabianBusiness from '../../../images/ArabianBusiness.png';
import Phones from '../../../images/phones.png';
import { PreRegisterModal } from '../../Modals/PreRegisterModal/PreRegisterModal';
import { useBool } from '../../../hooks/useBool';

export const Heading = () => {
  const modalOpened = useBool();

  return (
    <>
      <div className={styles.slogan}>
        <Typography variant='h0' align='center' className={styles.title}>
          Digital banking turned into ‘ibada for Muslims
        </Typography>
        <Typography variant='pMediumSemibold' align='center' font='Brahma' className={styles.subtitle}>
          Yoosr is a financial app that turns financial life for Muslims from skating on thin ice into a way of ‘ibada. Clear of interest-based capital and doubtful practices, it helps to receive reward from managing and spending money.
        </Typography>
      </div>
      <div className={styles.buttons}>
        <button className={styles.basicButton} onClick={modalOpened.onTrue}>
          <Typography variant='h3' color="var(--tertiary)" className={styles.basicButton__text} font='Brahma'>Pre-register</Typography>
        </button>
        <a href={OutsideLinks.Twitter} target="_blank" rel="noreferrer">
          <button className={styles.socialButton}>
            <SocialX />
          </button>
        </a>
        <a href={OutsideLinks.Instagram} target="_blank" rel="noreferrer">
          <button className={styles.socialButton}>
            <Instargram />
          </button>
        </a>
        <a href={OutsideLinks.Linkedin} target="_blank" rel="noreferrer">
          <button className={styles.socialButton}>
            <Linkedin />
          </button>
        </a>
      </div>
      <img src={Phones} className={styles.img} alt="Phones" />
      <div className={styles.partners}>
        <img src={ArabianBusiness} alt="ArabianBusiness" />
        <img src={Nasdaq} alt="Nasdaq" />
        <img src={TechTimes} alt="TechTimes" />
      </div>
      <PreRegisterModal open={modalOpened.value} onClose={modalOpened.onFalse} />
    </>
  )
}